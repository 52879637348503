<template>
  <marquee  scrollamount="7" :repeat="10" scrolldelay="70" onmouseover="this.stop();" onmouseout="this.start();">
    <h6 class="mb-0 custom" v-if="marquees.length>0">
      <a v-for="(mk,key) in marquees" :href="mk.link" :style="`color:${mk.text_color};` + `background-color:${mk.background_color}`" style="margin-right: 100px;" :key="key">{{mk.title}}</a>
    </h6>
  </marquee>
</template>
<script>
export default {
  name: 'Marque',
  created(){
    if(this.marquees.length <1) this.$store.dispatch('GET_ALL_MARQUEE');
  },
  computed:{
    marquees(){
      return this.$store.getters.marquees;
    }
  }
}
</script>
<style>
.custom{

}
</style>