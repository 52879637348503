<template>
  <div class="container">
    <b-carousel ref="main_carousel"
        id="carousel-1"
        v-model="slide"
        :interval="interval"
        controls
        indicators

        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333;"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
    >

      <!-- Slides with custom text -->
      <b-carousel-slide v-for="(slider,key) in sliders" :img-src="slider.image" :key="key">
      </b-carousel-slide>
    </b-carousel>
    <div class="mt-2 justify-content-end" style="float: right; margin-right: 10px;">
      <b-icon icon="skip-start" @click="start1()" scale="1" class="mr-2"  style="cursor: pointer;color: rgba(189,189,185);font-size: 20px"></b-icon>
      <b-icon icon="pause-fill" @click="pause1()" class="text_custom" style="cursor: pointer;font-size: 20px"></b-icon>
    </div>
  </div>
</template>

<script>
import  Vue  from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  data() {
    return {
      slide: 0,
      sliding: null,
      interval:3000,
    }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    },
    pause1(){
        this.interval=0;
        this.sliding=false;
    },
    start1(){
      this.interval=3000;
      this.sliding=true;
    }
  },
  created() {
    if(this.sliders.length <1) this.$store.dispatch('GET_ALL_SLIDER');
  },
  computed:{
    sliders(){
      return this.$store.getters.main_sliders;
    }
  }
}
</script>
<style >
.carousel-indicators ul{
  margin-top: 100px;
}
.carousel-indicators li {
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background-color: rgba(157, 163, 61);
  color: rgba(157, 163, 61);
}
.carousel-indicators .active {
  background-color: rgba(123,127,109);
}
.text_custom{
  color: rgba(112, 131, 53);
}
.text-custom2{
  color: rgba(123,127,109);
}
.carousel-indicators {
  justify-content: left;
  bottom: -45px;
  left: -15.5%;
}
</style>