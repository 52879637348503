<template>
  <div>
    <div id="pc_section">
      <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="4000"
          controls
          img-width="1024"
          img-height="480"
          style="text-shadow: 1px 1px 2px #333;"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
      >
        <b-carousel-slide v-for="(sld,key) in formattedClubs()" :key="key">
          <template v-slot:img>
            <b-card-group deck>
              <b-card v-for="(sl,key2) in sld" :key="key2"
                      :img-src="sl.image"
                      :img-alt="sl.title"
                      img-top
                      tag="article"
                      style="max-width: 13.2rem;"
                      class="slide_background mr-1"
              >
                <b-card-text class="c_color">
                  {{ sl.title }}
                </b-card-text>
                <router-link :to="{name:'slider_details',params:{id:sl.id}}">
                  <b-button style="float: right;margin-right: 3%" variant="primary">View</b-button>
                </router-link>
              </b-card>
            </b-card-group>
          </template>
        </b-carousel-slide>
        <!-- Slides with img slot -->
      </b-carousel>
    </div>
    <div id="mobile_section" class="mt-1">
      <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="4000"
          controls
          img-width="1024"
          img-height="480"
          style="text-shadow: 1px 1px 2px #333;"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
      >
        <b-carousel-slide v-for="(sld,key) in sliders" :key="key">
          <template v-slot:img>
            <b-card-group deck>
              <b-card
                  :img-src="sld.image"
                  :img-alt="sld.title"
                  img-top
                  tag="article"
                  class="slide_background"
              >
                <b-card-text class="c_color">
                  {{ sld.title }}
                </b-card-text>
                <router-link :to="{name:'slider_details',params:{id:sld.id}}">
                  <b-button style="float: right" variant="primary">View</b-button>
                </router-link>
              </b-card>
            </b-card-group>
          </template>
        </b-carousel-slide>
        <!-- Slides with img slot -->
      </b-carousel>
    </div>
  </div>

</template>

<script>

export default {
  props:{
    sliders:{
      type:Array,
      required:true,
    }
  },
  data() {
    return {
      slide: 0,
      sliding: null,
    }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    },
    formattedClubs() {
      return this.sliders.reduce((c, n, i) => {
        if (i % 3 === 0) c.push([]);
        c[c.length - 1].push(n);
        return c;
      }, []);
    }
  },
}
</script>
<style scoped>
.c_color{
  color: rgba(112, 131, 53);
}
.slide_background{
  background-color: rgba(58, 61, 51);
}
.carousel-control-prev, .carousel-control-next {
  width: 7%;
}
@media screen and (max-width: 600px) {
  #pc_section {
    visibility: hidden;
    clear: both;
    float: left;
    display: none;
  }
}
@media screen and (min-width: 780px) {
  #mobile_section {
    visibility: hidden;
    clear: both;
    float: left;
    display: none;
  }
}
</style>
<style>
.carousel-control-prev, .carousel-control-next {
  width: 7%;
}
</style>